<template>
<div class="breadcrumb">
	<span :class="index >= 0 ? 'active' : ''">{{ $store.strings.camera_front }}</span>
	<span :class="index >= 1 ? 'active' : ''">{{ $store.strings.camera_side }}</span>
	<span :class="index >= 2 ? 'active' : ''">{{ $store.strings.camera_top }}</span>
</div>
</template>

<script>
export default {
	name: 'CamBreadcrumb',
	props: {
		index: Number,
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.breadcrumb {
	line-height: 1;
	font-size: 14px;
	margin-top: -.75em;
	span {
		&:not(:last-child):after {
			content: '';
			display: inline-block;
			height: 10px;
			width: 10px;
			background-image: url("../assets/chevron-right.svg");
			background-repeat: no-repeat;
			background-position: center;
			margin: 0 10px;
		}
		&.active{
			font-weight: 700;
		}
	}
}
</style>
